import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Driver } from "../Pages/Trucks/TruckTypes";
import { UI } from "@newageerp/nae-react-ui"
import { useHistory } from "react-router-dom";
import DateField from "./fields/DateField";

interface Props {
    element: Driver,
    saveData: (firstName: string,
        lastName: string,
        birthDate: string,
        inDate: string,
        outDate: string,
        comment: string,
    ) => void,
}

export default function DriverForm(props: Props) {
    const { element, saveData } = props;
    const [firstName, setFirstName] = useState(element.firstName);
    const [lastName, setLastName] = useState(element.lastName);

    const [birthDate, setBirthDate] = useState(element.birthDate);
    const [inDate, setInDate] = useState(element.inDate);
    const [outDate, setOutDate] = useState(element.outDate);

    const [comment, setComment] = useState(element.comment);


    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    return (
        <UI.Card.WhiteCard>
            <UI.MVC.MvcViewTitle
                onBack={goBack}
                title={"Vairuotojas"}

            />

            <div>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Vardas</Form.Label>
                            <Form.Control value={firstName} onChange={e => setFirstName(e.target.value)} />
                        </Form.Group>

                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Pavardė</Form.Label>
                            <Form.Control value={lastName} onChange={e => setLastName(e.target.value)} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} sm={4}>
                            <Form.Label>Gimimo data</Form.Label>
                            <DateField value={birthDate} setValue={e => setBirthDate(e)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={4}></Form.Group>
                        <Form.Group as={Col} sm={4}></Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4}>
                            <Form.Label>Priėmimo data</Form.Label>
                            <DateField value={inDate} setValue={e => setInDate(e)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={4}>
                            <Form.Label>Atleidimo data</Form.Label>
                            <DateField value={outDate} setValue={e => setOutDate(e)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={4}></Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} sm={8}>
                            <Form.Label>Komentaras</Form.Label>
                            <Form.Control as={"textarea"} value={comment} onChange={e => setComment(e.target.value)} />
                        </Form.Group>
                    </Form.Row>
                </Form>


            </div>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                            onClick={() => saveData(
                                firstName,
                                lastName,
                                birthDate,
                                inDate,
                                outDate,
                                comment
                            )}>Išsaugoti</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </UI.Card.WhiteCard>)
}
