import React, {Fragment, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap'
import ApiUpload from "../../../service/ApiUpload";
import UploadsListPage from "./UploadsListPage";
import {useHistory} from 'react-router-dom'
import {UI} from '@newageerp/nae-react-ui'

export default function UploadPage() {
    const history = useHistory();
    const [state, setState] = useState('');

    const [salesOrPurchases, setSalesOrPurchases] = useState("sales")

    const [selectedType, setSelectedType] = useState("0");
    const [rewriteInvoice, setRewriteInvoice] = useState("0");
    const [file, setFile] = useState();
    const uploadData = () => {
        if (!!file && !!selectedType) {
            setState('upload');
            const formData = new FormData();
            // @ts-ignore
            formData.append('file', file);
            formData.append('type', selectedType);
            formData.append('rewriteInvoice', rewriteInvoice);

            ApiUpload.upload(formData).then(res => {
                if (res.id) {
                    setState('');
                    history.push('/imported-file/' + res.id)
                } else {
                    setState('');
                    alert('Klaida');
                }
            })
        }
    }


    const clientOptions = [
        { label: "DKV", value: "SN", category: "purchases" },
        { label: "AS24", value: "AS24", category: "purchases" },
        { label: "EuroWag", value: "Eurowag", category: "purchases" },
        { label: "Neste", value: "Neste", category: "purchases" },
        { label: "E100", value: "E100", category: "purchases" },
        { label: "WEX EUROPE", value: "WEX", category: "purchases" },
        { label: "DKV TISPL PAS (FR)", value: "TISPL_PAS", category: "purchases" },
        { label: "DKV PAS (IT)", value: "DKV_PAS", category: "purchases" },
        { label: "FVS pirkimai", value: "FVS_PURCHASE", category: "purchases" },
        { label: "CRT", value: "CRT", category: "purchases" },
        { label: "CEPSA", value: "CEPSA", category: "purchases" },
        { label: "TFC", value: "TFC", category: "purchases" },
        { label: "DSV pardavimai", value: "DSV_SALES", category: "sales" },
        { label: "TRANSIMEKSA pardavimai", value: "TRANSIMEKSA_SALES", category: "sales" },
        { label: "ALPI pardavimai", value: "ALPI_SALES", category: "sales" },
        { label: "LKW Walter pardavimai", value: "WALTER_SALES", category: "sales" },
        { label: "WITT Trucking pardavimai", value: "WITT_SALES", category: "sales" },
        { label: "WITT Trucking pardavimai (naujas)", value: "WITT_SALES_2", category: "sales" },
        { label: "NTG LT pardavimai", value: "NTG_SALES", category: "sales" },
        { label: "NTG DK pardavimai", value: "NTG_DK_SALES", category: "sales" },
        { label: "HELLMAN pardavimai", value: "HELLMAN", category: "sales" },
        { label: "Axis pardavimai", value: "AXIS_SALES", category: "sales" },
        { label: "Continexus pardavimai", value: "CONTINEXUS_SALES", category: "sales"},
        { label: "Fedex pardavimai", value: "FEDEX", category: "sales" },
        { label: "Transekspedicija pardavimai", value: "TRANSEKSPEDICIJA_SALES", category: "sales" },
      ];
    

      const filteredTipasOptions = clientOptions.filter(option => 
        option.category === salesOrPurchases
      );


    return (
        <Fragment>
            <Container>

                <UI.Card.WhiteCard className={"mb-2"}>
                    <UI.MVC.MvcViewTitle
                        title={"Įkelti failą"}
                    />
                    <div>
                        <Form>
                            <Form.Row className="w-1/3">
                                <Form.Group as={Col}>
                                <Form.Label>Kategorija</Form.Label>
                                <Form.Control as="select" className="mr-sm-2" value={salesOrPurchases} onChange={(e) => setSalesOrPurchases(e.target.value)}>
                                    <option value="sales">Pardavimai</option>
                                    <option value="purchases">Pirkimai</option>
                                </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Tipas</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}
                                    >
                                        <option value="0">Pasirinkite...</option>
                                        {filteredTipasOptions.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>


                                <Form.Group as={Col}>
                                    <Form.Label>Perrašyti sąskaita</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        value={rewriteInvoice}
                                        onChange={(e) => setRewriteInvoice(e.target.value)}
                                    >
                                        <option value="0">Ne</option>
                                        <option value={"10"}>Taip</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.File label="Failas"
                                               onChange={(e: any) => setFile(e.target.files[0])}/>
                                </Form.Group>

                            </Form.Row>
                        </Form>
                    </div>
                    <Card.Footer>
                        <Row>
                            <Col>

                            </Col>
                            <Col className={"text-right"}>
                                <Button type={"button"}
                                        variant={state === 'upload' ? "outline-success" : 'primary'}
                                        onClick={() => uploadData()}>{state === 'upload' ? 'Prašome palaukti' : 'Įkelti'}</Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </UI.Card.WhiteCard>
            </Container>
            <UploadsListPage/>
        </Fragment>
    )
}
