import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {toast} from "react-toastify";
import {Driver, Truck} from "./TruckTypes";
import DriverForm from "../../Forms/DriverForm";

const moduleName = 'driver';

interface ParamTypes {
    id: string
}

const defElement: Driver = {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    inDate: '',
    outDate: '',
    comment: '',
}

export default function DriversPage() {
    const history = useHistory();

    const [element, setElement] = useState(defElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (firstName: string,
                      lastName: string,
                      birthDate: string,
                      inDate: string,
                      outDate: string,
                      comment: string,
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    firstName,
                    lastName,
                    birthDate,
                    inDate,
                    outDate,
                    comment
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
                history.replace('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    firstName,
                    lastName,
                    birthDate,
                    inDate,
                    outDate,
                    comment,
                }
            ).then(res => {
                toast.success('Įrašas išsaugotas');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <DriverForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Fragment/>
    );

    return (<Fragment>

            <div className={"mb-2"}>
                <div className={"flex gap-2"}>
                    <div className={"flex-grow"}>
                        {form}
                    </div>
                    <div className={"w-96"}>
                        {rightComponent}
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
