import React, {Fragment, useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
// import useLocalStorage from "../../Hooks/useLocalStorage";
// import ApiProfile from "../../service/ApiProfile";
import {NaeNavBar} from 'nae-react-core-styles'
import {NaeAuthLogoutBtn} from 'nae-react-auth'
import {Dropdown, Nav} from "react-bootstrap";
import useLocalStorage from "../../Hooks/useLocalStorage";
import ApiProfile from "../../service/ApiProfile";
import NaePageWrapper from "../Main/PageWrapper";
import {UI} from "@newageerp/nae-react-ui"

export interface User {
    id: number,
    email: string,
}

interface ProviderValue {
    user: User
}

const defUser = {
    id: 0,
    email: '',
}

export const UserContext = React.createContext<ProviderValue>({
    user: defUser
})
export const useUser = () => useContext(UserContext);

interface Props {
    children: any,
}

export const UserProvider = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(defUser);
    const [token] = useLocalStorage('token', '');
    const history = useHistory();

    const navigate = (e: any, route: string) => {
        e.preventDefault();
        history.push('/' + route);
    }

    const getData = () => {
        ApiProfile.getProfile().then(res => {
            if (!!res.isError) {
                history.push('/login');
                return;
            }
            setUser(res);
            setLoaded(true);
        }).catch(e => {
            history.push('/login');
        })
    }
    useEffect(getData, [token, history]);

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <UserContext.Provider value={{
            user
        }}>
            {!!user.email ? <NaePageWrapper top={<NaeNavBar.NaeTopNavbar middleComponent={
                <Nav>
                    <Nav.Link href={'/upload'} onClick={(e: any) => navigate(e, 'upload')}>
                        <UI.Icons.FA type={"fad"} fixedWidth={true} icon={"upload"}/>
                    </Nav.Link>

                    <Nav.Item>
                        <Dropdown>
                            <Dropdown.Toggle variant="link">
                                Sąskaitos
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/invoices"
                                               onClick={(e) => navigate(e, 'invoices')}>Sąskaitos</Dropdown.Item>
                                <Dropdown.Item href="/invoice-lines"
                                               onClick={(e) => navigate(e, 'invoice-lines')}>Eilutės</Dropdown.Item>

                                <Dropdown.Item href="/reports"
                                               onClick={(e) => navigate(e, 'reports')}>Ataskaita</Dropdown.Item>

                                <Dropdown.Item href="/reports-fee"
                                               onClick={(e) => navigate(e, 'reports-fee')}>Mokėsčių ataskaita</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>

                    <Nav.Item>
                        <Dropdown>
                            <Dropdown.Toggle variant="link">
                                Duomenys
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/trucks"
                                               onClick={(e) => navigate(e, 'trucks')}>Vilkikai</Dropdown.Item>
                                <Dropdown.Item href="/fuel-cards"
                                               onClick={(e) => navigate(e, 'fuel-cards')}>Kortelės</Dropdown.Item>
                                <Dropdown.Item href="/tablets"
                                               onClick={(e) => navigate(e, 'tablets')}>Planšetės</Dropdown.Item>
                                <Dropdown.Item href="/truck-groups"
                                               onClick={(e) => navigate(e, 'truck-groups')}>Vilkikų grupės</Dropdown.Item>
                                <Dropdown.Item href="/truck-trailers" onClick={(e) => navigate(e, 'truck-trailers')}>Priekabų
                                    tipai</Dropdown.Item>

                                <Dropdown.Divider/>

                                <Dropdown.Item href="/services"
                                               onClick={(e) => navigate(e, 'services')}>Paslaugos</Dropdown.Item>
                                <Dropdown.Item href="/service-groups"
                                               onClick={(e) => navigate(e, 'service-groups')}>Paslaugų grupės</Dropdown.Item>
                                <Dropdown.Item href="/countries"
                                               onClick={(e) => navigate(e, 'countries')}>Šalys</Dropdown.Item>
                                <Dropdown.Item href="/stations"
                                               onClick={(e) => navigate(e, 'stations')}>Aptarnavimo
                                    stotys</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>


                    <Nav.Item>
                        <Dropdown>
                            <Dropdown.Toggle variant="link">
                                Pardavimai
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="/trips"
                                               onClick={(e) => navigate(e, 'trips')}>Kelionių lapai</Dropdown.Item>

                                <Dropdown.Item href="/trips-reports"
                                               onClick={(e) => navigate(e, 'trips-reports')}>Kelionių išklotinė</Dropdown.Item>

                                <Dropdown.Divider/>

                                <Dropdown.Item href="/invoices-trips"
                                               onClick={(e) => navigate(e, 'invoices-trips')}>Sąskaitos</Dropdown.Item>

                                <Dropdown.Item href="/drivers"
                                               onClick={(e) => navigate(e, 'drivers')}>Vairuotojai</Dropdown.Item>


                                <Dropdown.Item href="/trucks-locations"
                                               onClick={(e) => navigate(e, 'trucks-locations')}>Lokacijos</Dropdown.Item>

                                <Dropdown.Divider/>


                                <Dropdown.Item href="/trips-reports-km"
                                               onClick={(e) => navigate(e, 'trips-reports-km')}>Kilometrų paklaidos ataskaita</Dropdown.Item>

                                <Dropdown.Item href="/trips-reports-frigo"
                                               onClick={(e) => navigate(e, 'trips-reports-frigo')}>Kuro šaldytuvui ataskaita</Dropdown.Item>

                                <Dropdown.Item href="/reports-adblue"
                                               onClick={(e) => navigate(e, 'reports-adblue')}>Ad blue ataskaita</Dropdown.Item>

                                <Dropdown.Item href="/reports-fuel"
                                               onClick={(e) => navigate(e, 'reports-fuel')}>Kuro kainos ataskaita</Dropdown.Item>

                                <Dropdown.Item href="/trips-reports-fuel-km"
                                               onClick={(e) => navigate(e, 'trips-reports-fuel-km')}>Kuro sanaudų ataskaita</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>

                    <Nav.Link href={'/users'} onClick={(e: any) => navigate(e, 'users')}>
                        <UI.Icons.FA type={"fad"} fixedWidth={true} icon={"user"}/>
                    </Nav.Link>
                </Nav>
            } rightComponent={<NaeAuthLogoutBtn lang={"lt"}/>}/>}>
                {props.children}
            </NaePageWrapper> : <Redirect to={"/login"}/>}
        </UserContext.Provider>

    )
}
