import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import ApiRest from "../../../service/ApiRest";
import {Driver} from "./TruckTypes";
import {NaeTable} from "nae-react-core-styles";
import TableWithPaging from "../../TableWithPaging";
import {UI} from "@newageerp/nae-react-ui"

const moduleName = 'driver';

interface Props {

}

export default function DriversListCard(props: Props) {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [drivers, setDrivers] = useState<Driver[]>([])
    const [dataToRender, setDataToRender] = useState<Driver[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setDrivers(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = drivers;
            if (search) {
                _data = _data.filter((item: Driver) => {
                    return item.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, drivers, filter, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
            })
        }
        setTimeout(() => {
            setReadyToLoad(true);
        }, 300)
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: Driver) => {
        history.replace('/drivers', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return (

        <UI.Card.WhiteCard>
            <div className={"flex items-center gap-2"}>
                <UI.Typography.H3 className={"flex-grow"}>
                    Vairuotojai
                </UI.Typography.H3>
                <UI.Buttons.Button icon={"fal fa-fw fa-plus"} onClick={goToNew}>
                    Naujas
                </UI.Buttons.Button>
            </div>

            <div className={"flex items-center gap-2"}>
                <UI.Form.Input placeholder={"Paieška"} className={"flex-grow"} value={search}
                               onChange={(e) => setSearch(e.target.value)}/>

                {/*<UI.Form.Select className={"w-72"} value={filter} onChange={e => setFilter(e)} options={[*/}
                {/*    {value: "", label: "Filtras"},*/}
                {/*    {value: "no_group", label: "Nepriskirtas parkas"},*/}
                {/*    {value: "no_trailer", label: "Nepriskirtas priekabos tipas"},*/}
                {/*    {value: "has_loctracker", label: "Priskirtas LocTracker numeris"},*/}
                {/*    {value: "no_loctracker", label: "Nepriskirtas LocTracker numeris"},*/}
                {/*]}/>*/}
            </div>
            <TableWithPaging
                activePage={activePage}
                setActivePage={setActivePage}
                dataToRender={dataToRender} head={
                <tr>
                    <NaeTable.th id={true}>ID</NaeTable.th>
                    <NaeTable.th>Vardas, pavardė</NaeTable.th>
                    <NaeTable.th>Gimimo data</NaeTable.th>
                    <NaeTable.th>Priėmimo data</NaeTable.th>
                    <NaeTable.th>Atleidimo data</NaeTable.th>
                    <NaeTable.th>Komentaras</NaeTable.th>
                </tr>
            } renderItem={(item: Driver) => {
                return (
                    <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                        <NaeTable.td id={true}>{item.id}</NaeTable.td>
                        <NaeTable.td link={true}>{item.fullName}</NaeTable.td>
                        <NaeTable.td>{item.birthDate}</NaeTable.td>
                        <NaeTable.td>{item.inDate}</NaeTable.td>
                        <NaeTable.td>{item.outDate}</NaeTable.td>
                        <NaeTable.td>{item.comment}</NaeTable.td>
                    </tr>
                )
            }}
            />

        </UI.Card.WhiteCard>

    );
}
